<template>
	<div>
		<div ref="container">
			<canvas id="three"></canvas>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';


export default {
	name: "",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {}
	},
	methods: {

	},
	mounted() {
		// 加载场景和摄像机
		const scene = new THREE.Scene();
		const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
		camera.position.set(0, 0, 1);

		// 加载渲染器
		const renderer = new THREE.WebGLRenderer();
		renderer.setSize(window.innerWidth, window.innerHeight);
		renderer.setClearColor(0xffffff, 1); // 设置背景颜色为白色
		document.body.appendChild(renderer.domElement);
		// 设置渲染器大小
		renderer.setSize(window.innerWidth, window.innerHeight);
		this.$refs.container.appendChild(renderer.domElement);

		// 加光源
		const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
		scene.add(ambientLight);


		// 导入模型文件
		const loader = new OBJLoader();
		const mtlLoader = new MTLLoader();

		loader.load('../public/car.obj', (object) => {
			scene.add(object);
		});

		mtlLoader.load('../public/car.mtl', (meterials) => {
			meterials.preload();
			loader.setMaterials(meterials);
		});

		const animate = function () {
			requestAnimationFrame(animate);
			renderer.render(scene, camera);
		};

		const controls = new OrbitControls(camera,renderer.domElement);
		controls.enableDamping = true;
		controls.update()

		animate();
	}
}
</script>

<style>

</style>